import React, { useState, useEffect } from 'react';
import Image from '../../assets/drive-assets/erol-ahmed-FTy5VSGIfiQ-unsplash (1).jpg';
import Tick from './images/tick.svg';
import { useNavigate } from 'react-router-dom';
import { Button, Spin } from 'antd';
import axios from 'axios';
import { notification } from 'antd'

const VerificationDocumentUploadApprovalByAdmin = () => {
    const navigator = useNavigate();
    const [unverifiedUsers, setUnverifiedUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch unverified users from the backend
        const fetchUnverifiedUsers = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}admin/unVerifiedUsers`);
                setUnverifiedUsers(response.data);

				// axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}posts/${id}`)
				// .then(res => setPosts(res.data))
				// .catch(err => console.error(err));

                setLoading(false);
            } catch (error) {
                console.error('Error fetching unverified users', error);
                setLoading(false);
            }
        };

        fetchUnverifiedUsers();
    }, []);

    const verificationDocumentApproved = async (userId) => {
        try {
            // Call backend API to approve the user
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}admin/approveNewUser`, { userId });
            if (response.status === 200) {
                // Update UI after approval
                setUnverifiedUsers(unverifiedUsers.filter(user => user._id !== userId));

                        notification['success']({
                            message: 'User Approved successfully',
                            duration: 5,
                            onClick: () => {
                                notification.close()
                            },
                        })
                console.log('User approved successfully');
            }
        } catch (error) {
            console.error('Error approving user', error);
        }
    };

    return (
        <div className='min-h-screen max-h-[100vh] flex justify-center'>
            {/* <div className='w-[50%] h-full hidden lg:block rounded-[0_80px_0px_0]'>
                <img className='w-full h-full object-cover' src={Image} alt='Side Image' />
            </div> */}
            <div className='w-[50%] max-lg:w-full h-full flex flex-col justify-center items-center'>
                {loading ? (
                    <Spin size='large' />
                ) : (
                    <div className='flex flex-col justify-center items-center mt-40'>
                        {unverifiedUsers.length > 0 ? (
                            unverifiedUsers.map(user => (
                                <div key={user._id} className='flex flex-col justify-center items-center mb-8 border-2 border-black p-4 w-[90%]'>
                                    <img
                                        className='object-cover pb-4'
                                        src={user.verificationDocument}
                                        alt={`${user.name}'s Verification Document`}
                                    />
                                    <p className='w-[60%] text-[#2A2A2A] font-[600] text-[18px] leading-[26px] pt-4 pb-4 text-center'>
                                        Approve verification for {user.name}
                                    </p>
                                    <div className='w-[60%] pt-4 text-center'>
                                        <Button
                                            className='btn-primary'
                                            block
                                            onClick={() => verificationDocumentApproved(user._id)}
                                        >
                                            Approve
                                        </Button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No unverified users found.</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default VerificationDocumentUploadApprovalByAdmin;
