import React, { useState } from 'react';
import Image from '../../assets/drive-assets/erol-ahmed-FTy5VSGIfiQ-unsplash (1).jpg';
import Tick from './images/tick.svg';
import { useNavigate } from 'react-router-dom';
import { Button, Switch } from 'antd';
// import 'antd/dist/antd.css';

const AdministratorControl = () => {
    const navigator = useNavigate();
    const [feature1Enabled, setFeature1Enabled] = useState(false);
    const [feature2Enabled, setFeature2Enabled] = useState(false);

    const handleFeature1Toggle = (checked) => {
        setFeature1Enabled(checked);
        // You can perform further actions here based on the toggle state
    };

    const handleFeature2Toggle = (checked) => {
        setFeature2Enabled(checked);
        // You can perform further actions here based on the toggle state
    };

    return (
        <div className='min-h-screen max-h-[100vh] flex'>
            <div className='w-[50%] h-full hidden lg:block rounded-[0_80px_0px_0]'>
                <img className='w-full h-full object-cover' src={Image} alt='' />
            </div>
            <div className='w-[50%] max-lg:w-full h-full flex flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center mt-40'>
                    <img className='w-[30%] object-cover pb-4' src={Tick} alt='' />
                    <p className={`w-[60%] text-[#2A2A2A] font-[600] text-[18px] leading-[26px] pt-4 pb-4 text-center`}>
                        This is admin page
                    </p>
                    <div className='w-[60%] pt-4 text-center'>
                        <Button className='btn-primary' block onClick={() => navigator('/home/about')}>
                            Get Started
                        </Button>
                    </div>
                    <div className='w-[60%] pt-4 text-center'>
                        <p>Feature 1:</p>
                        <Switch checked={feature1Enabled} onChange={handleFeature1Toggle} />
                    </div>
                    <div className='w-[60%] pt-2 text-center'>
                        <p>Feature 2:</p>
                        <Switch checked={feature2Enabled} onChange={handleFeature2Toggle} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdministratorControl;
