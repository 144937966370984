import { BasicSignupInfo, OtpVerification, PreferenceSignupInfo, VerificationSignupInfo } from '../../pages/authPage/components/signupInfo'
import { useGenerateOTP, useValidateOTP, useVerificationTestFunction } from '../../pages/authPage/helpers/signupFinishHooks'

export const AUTH_EVENTS = {
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT',
	SIGNUP: 'SIGNUP',
	RESET_PASSWORD: 'RESET_PASSWORD',
}

export const SignupFlowEnum = {
	pages: [
		{
			title: 'Basic Information',
			watchField: 'all',
			useFinishFunction: useGenerateOTP,
			columns: [{ widthClassName: 'max-lg:[55%]', element: BasicSignupInfo }],
			// columns: [{ widthClassName: 'w-full', element: VerificationSignupInfo }], just for testing the new flow
			buttons: [
				{ type: 'back', title: 'CANCEL', className: 'btn-secondary' },
				{ type: 'submit', title: 'NEXT', className: 'btn-primary' },
			],
		},
		{
			title: 'Code',
			watchField: 'all',
			width: 'w-full',
			useFinishFunction: useValidateOTP,
			columns: [{ widthClassName: 'w-full', element: OtpVerification }],
			buttons: [
				{ type: 'back', title: 'BACK', className: 'btn-secondary' },
				{ type: 'submit', title: 'NEXT', className: 'btn-primary' },
			],
		},
		{
			title: 'Preference',
			watchField: 'all',
			width: 'w-full',
			useFinishFunction: useVerificationTestFunction, // Added by Ali Sarib
			checkButtonDisable: ({ termsAndConditions }) => {
				return !termsAndConditions
			},
			columns: [{ widthClassName: 'w-full', element: PreferenceSignupInfo }],
			buttons: [
				{ type: 'back', title: 'BACK', className: 'btn-secondary' },
				{ type: 'submit', title: 'NEXT', className: 'btn-primary' },
				// { type: 'dispatch', title: 'SIGNUP', className: 'btn-primary' },
			],
		},
		// New code below for verification by Ali Sarib
		{
			title: 'Verification',
			watchField: 'all',
			width: 'w-full',
			checkButtonDisable: ({ termsAndConditions }) => {
				return !termsAndConditions
			},
			columns: [{ widthClassName: 'w-full', element: VerificationSignupInfo }],
			buttons: [
				{ type: 'back', title: 'BACK', className: 'btn-secondary' },
				{ type: 'dispatch', title: 'SIGNUP', className: 'btn-primary' },
			],
		},
	],
}
