import Image from '../../assets/drive-assets/erol-ahmed-FTy5VSGIfiQ-unsplash (1).jpg'
import HourGlass from './images/hourGlass.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { auth, firebase } from '../../auth/firebase/config'
import { userAtom, chatCountAtom } from '@atoms'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { Button } from 'antd'

const VerificationPending = () => {
	const navigator = useNavigate()

	const [user, setUserAtom] = useRecoilState(userAtom)

	const logout = () => {
		firebase.auth().signOut()
		setUserAtom(null)
		localStorage.setItem('user', JSON.stringify(null))
		localStorage.setItem('token', JSON.stringify(null))
		navigator('/home/about')
	}

	return (

		<div className=' min-h-screen max-h-[100vh] flex'>
			<div className='w-[50%] h-full hidden lg:block rounded-[0_80px_0px_0]'>
				<img className='w-full h-full object-cover' src={Image} alt='' />
			</div>
			<div className='w-[50%]  max-lg:w-full h-full flex flex-col justify-center items-center'>
			<Button
						className='mr-8 mt-8 self-end btn-primary'
						onClick={logout}
					>
						<p size={1} className='text-white pl-2 '>
							LOGOUT
						</p>
					</Button>
				<div className='flex flex-col justify-center items-center  2xl:mt-80 mt-40'>
					<img className='w-[30%] object-cover pb-4' src={HourGlass} alt='' />
					<p className={`w-[60%] text-[#454545] font-[600] text-[16px] leading-[26px] pt-4 pb-4 text-center`}>
						Your account's verification is pending. It may take up to 2-3 business days.
					</p>
					<div className='w-[60%] pt-4 text-center' onClick={() => {navigator('/home/about')}}>
							
								{/* <Button className='btn-primary' block >
									Get Started
								</Button> */}
							
						</div>
				</div>
			</div>
		</div>
	)
	
}

export default VerificationPending
