
export const verificationFormSchema = (action) => {
	return [
		{
			type: 'checkbox',
			key: 'receiveEmails',
			name: ['receiveEmails'],
			label: 'I agree to receive general emails and product offers from CitySwapp',
			elementClassName: 'text-[8px] font-normal',
			itemClassName: '!mb-0 !w-full',
			className: '!mb-0 !w-full',
			customWidth: true,
			required: true,
			initialValue: true,
		},
		{
			type: 'checkbox',
			key: 'termsAndConditions',
			name: ['termsAndConditions'],
			label: (
				<div>
					I agree to City Swapp's&nbsp;
					<a
						className='text-blue-800 hover:text-blue-500 hover:underline hover:underline-offset-2'
						href={`${process.env.REACT_APP_APP_URL}/terms-and-conditions`}
						rel='noreferrer'
						target='_blank'
					>
						Terms & Conditions
					</a>
					&nbsp;and&nbsp;
					<a
						className='text-blue-800 hover:text-blue-500 hover:underline hover:underline-offset-2'
						href={`${process.env.REACT_APP_APP_URL}/privacy`}
						rel='noreferrer'
						target='_blank'
					>
						Privacy Policy.
					</a>
				</div>
			),
			elementClassName: 'text-[8px] font-normal',
			itemClassName: '!mb-0 !w-full',
			className: '!mb-0 !w-full',
			customWidth: true,
			required: true,
		},
	]
}
