import { PrimaryHeader, Footer } from '@components'
import { useEffect } from 'react'
const Privacy = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
	}, [])
	return (
		<div className='h-full flex flex-col justify-between text-base'>
			<PrimaryHeader />
			<div class='flex flex-col lg:px-32 md:px-12 max-md:px-6 items-start pt-32 pb-32 min-h-[700px]'>
				<h1 class='text-2xl font-bold mb-4'>Website Data Privacy Policy</h1>
				<p class='mb-4'>
					<strong>Last Updated:</strong> 14th June 2024
				</p>
				<h2 class='text-xl font-bold mb-4'>Privacy Policy</h2>
				<p class='mb-4'>
					This Privacy Policy (hereinafter referred to as the ‘Policy’) is that of the company that publishes the CitySwapp.com Website (hereinafter referred to
					as ‘Website’):
				</p>
				<p class='mb-4'>CitySwapp.com Inc. sydney, Australia.</p>
				<p class='mb-4'>As the data controller, City Swapp Inc is very committed to protecting your personal data and respecting your privacy.</p>
				<p class='mb-4'>
					The purpose of this Policy is to inform you of our practices regarding the collection, use, and sharing of information that you may provide to us when
					you use our City Swapp website, accessible at <a href='www.CitySwapp.com/en/'>www.CitySwapp.com/en/</a> (hereinafter referred to as City Swapp).
				</p>
				<p class='mb-4'>
					We encourage you to carefully read this Policy, which applies when you use City Swapp, in order to know and understand our practices regarding the
					processing of your personal data that we handle as well as your rights.
				</p>
				<p class='mb-4'>
					This Policy shall take effect as of 14/06/2023. It is subject to change at any time; in the event of substantial changes, we will inform you within a
					reasonable time period and, if necessary, request your consent again before it comes into effect. You are responsible for ensuring we have an
					up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.
				</p>
				<h3 class='text-lg font-bold mb-4'>Purpose of the Policy</h3>
				<p class='mb-4'>
					To enable you to make the best use of our City Swapp service, we may collect and use some of your information. This Policy aims to inform you of our
					practices and the reasons for them, in order to guarantee transparency for our users.
				</p>
				<p class='mb-4'>We undertake to use your information in compliance with the laws relating to the protection of personal data.</p>
				<h3 class='text-lg font-bold mb-4'>The information we collect</h3>
				<p class='mb-4'>We collect and process the following data:</p>
				<p class='mb-4'>
					<strong>2.1. Required information that you transmit directly to us</strong>
				</p>
				<p class='mb-4'>
					Depending on the use of our services on City Swapp, certain information must be provided. This is the data you transmit directly to us.
				</p>
				<p class='mb-4'>
					This information is required, for example, to create your account or to establish communication between your guests and yourself. These data are
					necessary so that we can provide you with the related service.
				</p>
				<p class='mb-4'>
					2.1.1 Information concerning your identity and contact details, such as your surname, first name, email, and password. This information is required
					when you create your City Swapp account.
				</p>
				<p class='mb-4'>
					2.1.2 Information concerning your accommodation, such as the nature of your accommodation (flat or house, principal or secondary residence), its
					location, surface area, number of bedrooms and bathrooms, number of beds, amenities, and at least one photograph of your accommodation. This
					information is needed to add it to City Swapp.
				</p>
				<p class='mb-4'>
					2.1.3 Information concerning your calendar, such as dates and periods of availability of your accommodation. This information is necessary when you
					wish to share your home with the City Swapp community.
				</p>
				<p class='mb-4'>
					2.1.4 Bank details, such as your credit card number, visual ciphertext, expiry date. This information is required when you pay for a service online.
					These data, with the exception of the expiry date, are collected and processed directly by our payment service provider and we never store them. We
					keep the expiry date so that we can notify you when it expires and must, therefore, be renewed.
				</p>
				<p class='mb-4'>
					2.1.5 Evidence required for guarantees, such as proof of cancellation or damage you have suffered. These data may be transmitted to our financial
					partners.
				</p>
				<p class='mb-4'>
					<strong>2.2. Additional and optional information that you may send us</strong>
				</p>
				<p class='mb-4'>
					When using our services on City Swapp, in order to benefit from an enhanced user experience, you may be asked to provide us with additional categories
					of information, the provision of which is optional, such as the following:
				</p>
				<p class='mb-4'>
					2.2.1 Additional information enriching your profile, such as your photograph, your date of birth, your telephone number, your description of yourself
					in your profile, languages spoken, some of your preferences, the various social networks on which you are registered.
				</p>
				<p class='mb-4'>
					2.2.2 Additional information enriching your accommodation, such as photographs of your home, your description of your home, your neighborhood.
				</p>
				<p class='mb-4'>
					2.2.3 Proof of identity, such as a copy of your identity card, passport, and proof of accommodation that you have added on City Swapp. This
					information may enable us to verify that the information provided is accurate in order to reassure the City Swapp community and protect our members
					from fraud.
				</p>
				<p class='mb-4'>
					2.2.4 Publications, friend lists and other social interactions, such as the groups and clubs you create or join, the friends you add, the comments you
					write and the ratings you give.
				</p>
				<p class='mb-4'>
					2.2.5 Private messaging. We also collect the private messages that you exchange with other members, but we treat them as confidential private
					correspondence in accordance with applicable regulations.
				</p>
				<p class='mb-4'>
					<strong>2.3. The information we automatically collect</strong>
				</p>
				<p class='mb-4'>
					Data may be automatically collected as you navigate our Website. We also automatically collect certain categories of information such as:
				</p>
				<p class='mb-4'>
					2.3.1 Information arising from your use of our City Swapp services, such as the pages you visit, the searches you save, your search terms, the home
					swaps and subleases you make, your response rate and time to questions asked by other members, your last login date.
				</p>
				<p class='mb-4'>
					2.3.2 Information concerning your equipment to access City Swapp, such as your internet browser, screen resolution, operating system version, and IP
					address.
				</p>
				<p class='mb-4'>
					2.3.3 Connection logs that correspond to our servers’ log files. The information contained may be your IP address, the type of browser used, your
					operating system, the pages viewed, the pages from which you came.
				</p>
				<p class='mb-4'>
					2.3.4 Cookies. We also use cookies that allow us to automatically collect certain information. For more information, see Section 8 (Our use of
					cookies) of this Policy.
				</p>
				<p class='mb-4'>
					<strong>The use of the data we collect</strong>
				</p>
				<p class='mb-4'>We use the data we collect for the following purposes:</p>
				<p class='mb-4'>
					<strong>Purpose Legal Basis</strong>
				</p>

				<p class='mb-4'>
					3.1 to execute contracts concluded between you and us or between our business partners and us and to provide you with the services requested
				</p>
				<p class='mb-4'>For example:</p>
				<ul class='list-disc ml-6 mb-4'>
					<li>Payment of the deposit: we need your direct debit authorization</li>
					<li>Verification: we need your proof of identity and a proof of address in order to give you verified membership status</li>
					<li>
						Guarantees: in the event of an incident, we need any proof related to the creation of a file, which must be kept until the file is resolved (photos,
						medical proof, repair and/or replacement estimates, etc.)
					</li>
				</ul>
				<p class='mb-4'>
					This processing is necessary for the performance of our respective contractual obligations, in accordance with our General Terms and Conditions of
					Use, accepted when creating your account and using City Swapp.
				</p>
				<p class='mb-4'>
					3.2 to allow you to customize your profile in order to share more information with the City Swapp community, socialize and reassure other City Swapp
					members who would like to welcome you to their accommodation or be welcomed at your accommodation.
				</p>
				<p class='mb-4'>For example:</p>
				<ul class='list-disc ml-6 mb-4'>
					<li>Your description of yourself, the languages you state you speak, the photograph added to your account</li>
				</ul>
				<p class='mb-4'>This processing is necessary for the performance of our respective contractual obligations.</p>
				<p class='mb-4'>
					<strong>
						3.3 to verify your identity and to certify your member account in order to enjoy the trust of the other members of the City Swapp community
					</strong>
				</p>
				<p class='mb-4'>This processing is necessary for the performance of our respective contractual obligations.</p>
				<p class='mb-4'>
					<strong>3.4 to answer your questions and assist you with your requests</strong>
				</p>
				<p class='mb-4'>For example:</p>
				<ul class='list-disc ml-6 mb-4'>
					<li>Questions about how to use our services</li>
					<li>To inform us of a dispute with another member</li>
					<li>To lodge a complaint or request to exercise your rights</li>
				</ul>
				<p class='mb-4'>This processing is necessary for the performance of our respective contractual obligations.</p>
				<p class='mb-4'>
					<strong>
						3.5 to send you newsletters and notifications regarding City Swapp community news and to notify you when there is activity on your account
					</strong>
				</p>
				<p class='mb-4'>For example:</p>
				<ul class='list-disc ml-6 mb-4'>
					<li>A member wants to become your friend</li>
					<li>A member replied to your messages</li>
				</ul>
				<p class='mb-4'>
					This processing is done based on your consent if you are not already a client of City Swapp. If you are already a client, this processing could be
					done based on our legitimate interest.
				</p>
				<p class='mb-4'>
					However, you can opt-out and ask us to stop sending these emails, by expressing your choice in your personal space (Once logged in, click on ‘My
					Profile’ then ‘Settings’) or by unsubscribing using the link at the bottom of our emails.
				</p>
				<p class='mb-4'>
					<strong>3.6 to send you emails and marketing messages</strong>
				</p>
				<p class='mb-4'>
					This processing is done based on your consent if you are not already a client of City Swapp. If you are already a client, this processing could be
					done based on our legitimate interest.
				</p>
				<p class='mb-4'>
					<strong>3.7 to prevent fraud and illegal activities</strong>
				</p>
				<p class='mb-4'>
					We have a legitimate interest in verifying certain suspicious behaviors of users on City Swapp and carrying out verifications of information, in order
					to combat fraud (identity theft) abuse (creation of false accounts) and any illegal activity (hacking) or activity contrary to public order. We
					believe that you can reasonably expect us to perform these transactions and that they do not infringe on your rights and freedoms.
				</p>
				<p class='mb-4'>We are likely to implement automated detection systems and to humanely verify the relevance of suspicions that our systems detect.</p>
				<p class='mb-4'>
					This processing is necessary in order to protect City Swapp members and protect them from attempts at fraud, which is a legitimate interest.
				</p>
				<p class='mb-4'>
					<strong>3.8 to analyze the use of our services</strong>
				</p>
				<p class='mb-4'>
					We analyze the behavior of visitors to our website (interactions on the pages, use of features, search terms) in order to develop it, improve the
					relevance of search suggestions, modify our interfaces and our use paths when we detect that they are not intuitive enough for visitors.
				</p>
				<p class='mb-4'>
					In addition, we regularly send questionnaires to all or part of our members in order to better understand their use of the site and help us improve
					it. Participation in these surveys is optional, and each time we specify the objective of the study, as well as the processing that will be made of
					the collected data.
				</p>
				<p class='mb-4'>
					This processing is necessary to provide you with better service and to allow our teams to make our website more attractive in order to facilitate your
					navigation, which is a legitimate interest.
				</p>
				<p class='mb-4'>
					<strong>3.9 to collect your payments or forward to you the sums collected on your behalf</strong>
				</p>
				<p class='mb-4'>This processing is necessary for the performance of our respective contractual obligations.</p>
				<p class='mb-4'>
					<strong>3.10 to store connection logs</strong>
				</p>
				<p class='mb-4'>For example:</p>
				<ul class='list-disc ml-6 mb-4'>
					<li>IP address</li>
					<li>Type of browser used</li>
					<li>Operating system</li>
					<li>Pages viewed</li>
					<li>Pages of provenance, times</li>
					<li>Dates of visit to the site</li>
				</ul>
				<p class='mb-4'>This processing is based on a legal obligation to keep logs of connections to City Swapp for a period of one year.</p>
				<p class='mb-4'>
					<strong>3.11 to perform profiling operations</strong>
				</p>
				<p class='mb-4'>Profiling consists, for example, of evaluating personal aspects, predicting behavior or preferences.</p>
				<p class='mb-4'>
					We do several types of profiling: one to recommend accommodations that could potentially please our users, another to improve the order of
					accommodations appearing in our search engine, and, finally, we calculate a notion of similarity between accommodations.
				</p>
				<p class='mb-4'>
					This processing is based on the legitimate interest to strengthen and personalize the service offering according to your interests.
				</p>
				<p class='mb-4'>
					<strong>3.12 to participate in studies and research</strong>
				</p>
				<p class='mb-4'>
					Because of our positioning and our business activity, we participate in university studies and research, for example, on the accommodation swapping
					market and the sharing economy. All your data are anonymized for the purpose of these studies and used for statistical purposes only.
				</p>
				<p class='mb-4'>This processing is anonymized.</p>
				<p class='mb-4'>
					<strong>3.13 to share your photos in our media</strong>
				</p>
				<p class='mb-4'>
					We may use certain accommodation photos in the company's communication materials (newsletter, blog, advertising, etc.). These photographs are
					anonymous and do not reveal any private elements and are taken in respect of image rights.
				</p>
				<p class='mb-4'>Contract-based (terms of use). However, you can opt out and ask us to stop using your media if you do not want to share it.</p>
				<p class='mb-4'>
					<strong>3.14 to receive commercial prospection messages from companies from our group</strong>
				</p>
				<p class='mb-4'>The companies from our group may send you messages in order to present you new services that could be interesting for you.</p>
				<p class='mb-4'>We will only share your email address with these companies.</p>
				<p class='mb-4'>This processing is done based on your consent.</p>
				<p class='mb-4'>
					<strong>4. Data retention periods</strong>
				</p>
				<p class='mb-4'>
					We have the obligation to store your personal data during the duration of use of our service HomeExchange. If you asked for the erasure of your
					profile, we archive your personal data for a period of time of 3 months in case you would want to restore your profile during this period. We also can
					erase your account after a period of inactivity of 3 years.
				</p>
				<p class='mb-4'>After these retention periods, we only store information:</p>
				<ul class='list-disc ml-6 mb-4'>
					<li>
						For legal and accounting obligations, we may retain certain information for a maximum period of 10 years from their date (commercial limitation
						period);
					</li>
					<li>
						For evidentiary reasons: information collected for management and technical assistance purposes may be kept for a maximum period of five years from
						their date (civil limitation period);
					</li>
					<li>
						For commercial purposes: we may retain certain information for a maximum period of 3 years from our last contact with you except in the case that you
						unsubscribe to such messages.
					</li>
				</ul>
				<p class='mb-4'>
					At the end of the retention periods summarized above, we delete all your personal data in order to guarantee your confidentiality for future years.
				</p>
				<p class='mb-4'>
					The deletion of your personal data is irreversible and we will no longer be able to communicate them to you after this period. At most, we can only
					keep anonymous data for statistical purposes.
				</p>
				<p class='mb-4'>
					Please also note that in the event of litigation, we have an obligation to keep all the data concerning you for the duration of the processing of the
					file, even after the expiry of their retention periods described above.
				</p>
				<p class='mb-4'>
					<strong>5. The recipients of our data</strong>
				</p>
				<p class='mb-4'>
					<strong>5.1. City Swapp members with whom you are communicating</strong>
				</p>
				<p class='mb-4'>
					When you exchange accommodation with another HomeExchange member, you will receive information from each other: this includes information about your
					identity, your contact details and the address of your accommodation.
				</p>
				<p class='mb-4'>
					<strong>5.2. Other companies belonging to our group</strong>
				</p>
				<p class='mb-4'>
					We will only share your data with those persons who are authorized to use it to implement our services. This may include our staff in charge of
					service implementation, accounting, marketing or even security of our premises. We may also pass on your data to certain members of our group or to
					technical service providers (e.g. hosting) in order to operate our service.
				</p>
				<p class='mb-4'>
					<strong>5.3. Business partners or third-party service providers</strong>
				</p>
				<p class='mb-4'>We work with third-party companies with whom we share some of your information, and who are:</p>
				<p class='mb-4'>
					<strong>5.3. Business partners or third-party service providers</strong>
				</p>
				<p class='mb-4'>We work with third-party companies with whom we share some of your information, and who are:</p>
				<ul class='list-disc ml-6 mb-4'>
					<li>
						<strong>5.3.1 Our service providers:</strong> we use a service provider only in connection with the performance of a contract between you and us or to
						provide and/or improve our services;
					</li>
					<li>
						By subscribing to our damage and cancellation offers, we are required to transfer your data to our insurance partners, for the sole purpose of the
						fulfilling of their contractual obligations. These data include:
					</li>
					<ul class='list-disc ml-6 mb-4'>
						<li>Identity information (name, last name...)</li>
						<li>Contact details (email, phone number…)</li>
						<li>Historical record of your relationship with City Swapp (seniority, number of swaps done…)</li>
					</ul>
					<li>
						<strong>5.3.2 Our business partners:</strong> we have business partners who can advertise our services and whose offers you can agree to subscribe to;
					</li>
					<li>
						<strong>5.3.3 Social networking platforms:</strong> we offer the possibility to use City Swapp and to connect via social networks and can use them to
						offer you the best offers.
					</li>
				</ul>
				<p class='mb-4'>
					<strong>Storage of your information</strong>
				</p>
				<p class='mb-4'>We prefer to store your information, as much as possible, within the Australian Region.</p>
				<p class='mb-4'>
					However, we may use service providers who process the information we provide to them outside Australia. When this happens, we first ensure that the
					recipient offers appropriate guarantees to process your information with a level of protection at least equivalent to the protection that Australian
					law offers you.
				</p>
				<p class='mb-4'>
					<strong>Transfer of your data</strong>
				</p>
				<p class='mb-4'>
					Unless strictly necessary and on an exceptional basis, we never transfer your data outside Australia and your data is always hosted in the Australian
					Region.
				</p>
				<p class='mb-4'>
					Should our service providers nevertheless transfer your personal data outside Australia, we take great care to ensure that they implement appropriate
					safeguards to ensure the confidentiality and protection of your data such as standard contractual clauses drafted by the Australian Commission or
					adequation decision.
				</p>
				<p class='mb-4'>
					<strong>Our use of cookies</strong>
				</p>
				<p class='mb-4'>We use cookies and trackers for various reasons:</p>
				<p class='mb-4'>
					<strong>8. Our use of cookies</strong>
				</p>
				<p class='mb-4'>We use cookies and trackers for various reasons:</p>
				<p class='mb-4'>
					<strong>8.1 Technical cookies</strong>
				</p>
				<p class='mb-4'>
					We need to store these cookies so that you can use the basic functions of our website and to offer you improved, personalized functions.
				</p>
				<p class='mb-4'>
					For example, technical cookies will allow us to know that you are connected, to offer you the language of your choice, to display the website
					according to your display preferences, to better manage the load on our servers, etc.
				</p>
				<p class='mb-4'>
					<strong>8.2 Audience measurement cookies</strong>
				</p>
				<p class='mb-4'>
					We place these cookies to better understand how you browse our website, what other website you came from, what content will get your attention, which
					pages you visit most regularly, etc. This allows us to improve our service.
				</p>
				<p class='mb-4'>
					<strong>8.3 Advertising cookies</strong>
				</p>
				<p class='mb-4'>
					We place advertising cookies to display ads that may be of interest to you. These advertising cookies collect your browsing habits on our website
					and/or on other websites on which these cookies are also present in order to create a profile of your interests. These third-party cookies are
					produced by the advertising agencies themselves.
				</p>
				<p class='mb-4'>
					<strong>8.4 Social network / third-party cookies</strong>
				</p>
				<p class='mb-4'>
					We place social network cookies to allow you to share content on the leading social networks. These third-party cookies are produced by the social
					networks themselves.
				</p>
				<p class='mb-4'>
					<strong>How can I prevent the use of cookies?</strong>
				</p>
				<p class='mb-4'>
					You can block the use of cookies in several ways. To block cookies, scroll to the bottom of the page and click on the button “Cookies Settings” and
					select the cookies you would like to be blocked.
				</p>
				<p class='mb-4'>
					<strong>Your rights</strong>
				</p>
				<p class='mb-4'>Depending on your jurisdiction, you may have several rights with respect to your information that we possess:</p>
				<p class='mb-4'>
					<strong>8. Our use of cookies</strong>
				</p>
				<p class='mb-4'>We use cookies and trackers for various reasons:</p>

				<p class='mb-4'>
					<strong>8.1 Technical cookies</strong>
				</p>
				<p class='mb-4'>
					We need to store these cookies so that you can use the basic functions of our website and to offer you improved, personalized functions.
				</p>
				<p class='mb-4'>
					For example, technical cookies will allow us to know that you are connected, to offer you the language of your choice, to display the website
					according to your display preferences, to better manage the load on our servers, etc.
				</p>
				<p class='mb-4'>
					<strong>8.2 Audience measurement cookies</strong>
				</p>
				<p class='mb-4'>
					We place these cookies to better understand how you browse our website, what other website you came from, what content will get your attention, which
					pages you visit most regularly, etc. This allows us to improve our service.
				</p>
				<p class='mb-4'>
					<strong>8.3 Advertising cookies</strong>
				</p>
				<p class='mb-4'>
					We place advertising cookies to display ads that may be of interest to you. These advertising cookies collect your browsing habits on our website
					and/or on other websites on which these cookies are also present in order to create a profile of your interests. These third-party cookies are
					produced by the advertising agencies themselves.
				</p>
				<p class='mb-4'>
					<strong>8.4 Social network / third-party cookies</strong>
				</p>
				<p class='mb-4'>
					We place social network cookies to allow you to share content on the leading social networks. These third-party cookies are produced by the social
					networks themselves.
				</p>
				<p class='mb-4'>
					<strong>How can I prevent the use of cookies?</strong>
				</p>
				<p class='mb-4'>
					You can block the use of cookies in several ways. To block cookies, scroll to the bottom of the page and click on the button “Cookies Settings” and
					select the cookies you would like to be blocked.
				</p>
				<p class='mb-4'>
					<strong>Your rights</strong>
				</p>
				<p class='mb-4'>Depending on your jurisdiction, you may have several rights with respect to your information that we possess:</p>
				<p class='mb-4'>
					<strong>Right of access</strong>
				</p>
				<p class='mb-4'>You may ask us to access any information we hold about you.</p>
				<p class='mb-4'>
					<strong>Right to rectification</strong>
				</p>
				<p class='mb-4'>You may ask us to correct your information.</p>
				<p class='mb-4'>Most of the time, you can make corrections yourself when you are connected to our website.</p>
				<p class='mb-4'>
					<strong>Right to object</strong>
				</p>
				<p class='mb-4'>You may ask us to stop using your information when we do so because of a legitimate interest.</p>
				<p class='mb-4'>
					You may also ask us to stop sending you direct marketing / prospecting messages. You can easily do this when you are connected to our website by going
					to the ‘My Profile’ tab and then ‘Settings’ or by unsubscribing by clicking on the link provided at the bottom of our emails.
				</p>
				<p class='mb-4'>
					<strong>Right to withdraw consent</strong>
				</p>
				<p class='mb-4'>
					Where you have given us your consent to use your information for a specific purpose, you may, at any time, decide to withdraw your consent and we will
					then stop using your information for that purpose.
				</p>
				<p class='mb-4'>You can easily do this when you are connected to our website by going to the ‘My Profile’ tab and then ‘Settings’.</p>
				<p class='mb-4'>
					<strong>Right to erasure or right to be forgotten</strong>
				</p>
				<p class='mb-4'>You may ask us, via email to our data protection officer, to delete your information or to stop using it.</p>
				<p class='mb-4'>
					We can only do this when possible: sometimes we need your information to comply with the law or simply to provide you with our services.
				</p>
				<p class='mb-4'>
					<strong>Right to restriction of processing</strong>
				</p>
				<p class='mb-4'>
					You may ask our data protection officer by email to block the use of your information, i.e. to temporarily store it but to stop using it.
				</p>
				<p class='mb-4'>
					<strong>Right to portability</strong>
				</p>
				<p class='mb-4'>You may contact our data protection officer to request an export of your information in a reusable format.</p>
				<p class='mb-4'>
					<strong>Right to lodge a complaint</strong>
				</p>
				<p class='mb-4'>
					You may lodge a complaint with the authority responsible for protecting personal data if you believe that your rights have not been respected.
				</p>
				<p class='mb-4'>
					<strong>Exercising your rights</strong>
				</p>
				<p class='mb-4'>
					For a request to be taken into account, it is imperative that it be made directly by you at CitySwapp@gmail.com. Any request that is not made in this
					manner cannot be processed.
				</p>
				<p class='mb-4'>
					Requests cannot be made by anyone other than you. We may therefore ask you for proof of identity if there is any doubt about the identity of the
					requester.
				</p>
				<p class='mb-4'>
					We will respond to your request as soon as possible, but no later than one month after receipt. The request can be processed in a maximum time of 3
					months in case the request is technically complex or if we receive many requests at the same time.
				</p>
				<p class='mb-4'>
					<strong>California Privacy Rights</strong>
				</p>
				<p class='mb-4'>The California Consumer Privacy Act (CCPA) protects the data rights of residents of the State of California, USA.</p>
				<p class='mb-4'>
					<strong>Children Under the Age of 15</strong>
				</p>
				<p class='mb-4'>
					Our Website is not intended for children under 15 years of age. No one under age 15 may provide any personal data to or on the Website. We do not
					knowingly collect personal data from children under 15. If you are under 15, do not use or provide any information on this Website or through any of
					its features. If we learn we have collected or received personal data from a child under 15 without verification of parental consent, we will delete
					that information. If you believe we might have any information from or about a child under 15, please contact us at{' '}
					<a href='mailto:CitySwapp@gmail.com' className='text-blue-500'>
						CitySwapp@gmail.com
					</a>{' '}
					.
				</p>
				<p class='mb-4'>
					<strong>Data Security</strong>
				</p>
				<p class='mb-4'>
					We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and
					disclosure. The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access
					to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
					Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal
					information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at
					your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
				</p>
				<p class='mb-4'>
					<strong>Contact Information</strong>
				</p>
				<p class='mb-4'>
					If you have any questions about this Policy or our privacy practices, please contact us at:{' '}
					<a href='mailto:CitySwapp@gmail.com' className='text-blue-500'>
						CitySwapp@gmail.com
					</a>
				</p>
			</div>
			<Footer />
		</div>
	)
}

export default Privacy
