import React, { useContext, useState, useRef } from 'react'
import { Modal, Upload } from 'antd'
import { firebase } from '@auth'
import { Form } from '@components'
import { mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import UploadPic from './upFull.png';
import './verificationPictureUpload.modules.css';

// test https://firebasestorage.googleapis.com/v0/b/cityswapp-55550.appspot.com/o/public%2Fimages%2Fblogs%2F1-malaysiaProperty.PNG?alt=media&token=bdfc09f7-ac9c-489f-845e-4f0c38b06fd6

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})

const normFile = (e) => {
	if (Array.isArray(e)) return e
	return e && e.fileList
}
const PropertyPictures = (props) => {

	const [fileList, setFileList] = useState([]);

	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')

	const DocumentUpload = async ({ file, onProgress, onSuccess, onError }) => {
		
		const response = firebase.storage
			.ref()
			.child(
				`public/images/verification/2-${file.name}`
			)
			.put(file)
		response.on(
			'state_changed',
			(snapshot) => onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }),
			(error) => onError(error),
			() => onSuccess(null, response.metadata_)
		)
	}
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
	}
	const ChangeFileList = async ({ fileList }) => {

			  // Limit the file list to only one file
			  const updatedFileList = fileList.slice(-1);
			  setFileList(updatedFileList);
		if (fileList.length > 0) {
			fileList.forEach((file, index) => {
				if (!file.url && file.status === 'done') {
					const response = firebase.storage
						.ref()
						.child(
							`public/images/verification/2-${file.name}`
						)
					response.getDownloadURL().then((result) => {
						fileList[index].url = result
						console.log("Image URL is " , result)
						props.setMainImage(fileList)
						
					})
				}
			})
		}
	}
	const handleCancel = () => setPreviewOpen(false)

	return (
		<>
			
			<Form.Item
				key={'photo-upload'}
				name={['photos']}
				rules={[{ required: true, message: `Please upload at least one document photo ` }]}
				valuePropName='fileList'
				getValueFromEvent={normFile}
			>
				<Upload
					className='max-md:w-full'
					customRequest={DocumentUpload}
					listType='picture-card'
					onPreview={handlePreview}
					fileList={fileList}
					onChange={ChangeFileList}
					multiple={false}  // Only one file allowed
					style={{ border: '2px dashed #4caf50', padding: '10px' }}
				>

				{fileList.length < 1 && <img  src={UploadPic} alt='' className='uploadPicImage' />}

				</Upload>
			</Form.Item>

			<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
				<img
					alt='example'
					style={{
						width: '100%',
					}}
					src={previewImage}
				/>
			</Modal>
		</>
	)
}
export default PropertyPictures
